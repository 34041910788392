import React from 'react';
import './FactoryGallery.scss';
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

import Img from 'gatsby-image'
import { Container } from 'react-bootstrap'

export default ({photos}) => {
    function getSizes(height, width) {
        if ( Math.abs(height - width) < 300 ) {
            return 'factory-gallery--photo_square'
        }
        else if (width > height) {
            return 'factory-gallery--photo_long-w'
        }
        else if (height >= width) {
            return 'factory-gallery--photo_long-h'
        }
        return '';
    }
    const options = {
        settings: {
        },
        buttons: {
          showDownloadButton: false,
        },
        caption: {
          captionColor: "#ffffff",
        }
      };
      
    return (
    <div className="factory-gallery">
        <Container fluid={'xl'}>
        <SimpleReactLightbox >
            <SRLWrapper options={options}>
                <div className="factory-gallery--grid">
                {
                photos.map((photo) => {
                    return (
                        <div className="factory-gallery--item" key={photo.file.url}>
                            <a href={photo.file.url}>
                                <Img className={'factory-gallery--photo ' + getSizes(photo.file.details.image.height, photo.file.details.image.width)} fluid={photo.fluid} alt={photo.title}/>
                            </a>
                        </div>

                    )
                })
            }
                </div>

            </SRLWrapper>
        </SimpleReactLightbox>
        </Container>
    </div>

    )
}