import React from 'react'
import './Sidebar.scss'
import { Col } from 'react-bootstrap'
import { Link } from 'gatsby'

function Sidebar({sectionNavigation, otherSections, currentPage, currentSection, extraItem}) {
    // console.log(sectionNavigation, 'asdasdad')
    function generateOtherSections(sections, curPage) {
        if (sections) {
            let resultArray = [];
            for (let i=0; i < sections.length; i++) {
                if (sections[i].slug !== curPage)
                    resultArray.push(sections[i]);
            }
            return (
                <nav className="sidebar--services-nav">
                    <span className='sidebar-services-nav--title'>Другие разделы</span>
                    <ul className='sidebar-services-nav--ul'>
                        {
                            resultArray.map(page => {
                                return (
                                    <li key={page.slug} className='sidebar-services-nav--li'>
                                        <Link 
                                            className='sidebar-services-nav--link' 
                                            to={`/${currentSection}/${page.slug}`}
                                        >
                                        {page.title}
                                        </Link>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </nav>
            )
        }
    }

    function generateSubNagivation(items, section) {
        // console.log(section, items, 'ASDASD')
        if (items) {
            return (
                <ul className="sidebar--nav sidebar-nav sidebar-nav_sub">
                    {
                        items.map(item => {
                            return (
                                <li key = {item.slug} className='sidebar-nav--li sidebar-nav--li_sub'>
                                    <Link
                                            to={ currentPage ? 
                                            `/${currentSection}/${currentPage}/${section.slug}/${item.slug}` :
                                            `/${currentSection}/${section.slug}/${item.slug}`
                                            }
                                            className='sidebar-nav--link sidebar-nav--link_sub'
                                            activeClassName='sidebar-nav--link_active'
                                        >
                                            {item.title}
                                        </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            )
        }
    }

    return (
        <Col xs={12} md={4} lg={3} className="sidebar pr-0">
            <div className="sidebar--content">
                    <span className='sidebar-nav--title'>Навигация по разделу</span>
                    <ul className="sidebar--nav sidebar-nav">
                    {extraItem ? 
                            <li className="sidebar-nav--li">
                                <Link 
                                    className='sidebar-nav--link'
                                    to={`/${extraItem.slug}`}
                                    activeClassName='sidebar-nav--link_active'
                                >
                                    {extraItem.title}
                                </Link>
                            </li> : ''
                        }
                        {
                            sectionNavigation.map(section => {
                                return (
                                    <li key = {section.slug} className='sidebar-nav--li'>
                                        <Link
                                            to={ currentPage ? 
                                            `/${currentSection}/${currentPage}/${section.slug}` :
                                            `/${currentSection}/${section.slug}`
                                            
                                            }
                                            className='sidebar-nav--link'
                                            activeClassName='sidebar-nav--link_active'
                                        >
                                            {section.title}
                                        </Link>
                                        {generateSubNagivation(section.subSection, section)}
                                    </li>
                                )
                            })
                        }
                        {/* {linkToComponent(ptitle)} */}
                    </ul>

                    {generateOtherSections(otherSections, currentPage)}
{/* 
                    <div className="sidebar--services-nav">
                        <span className='sidebar-services-nav--title'>Другие разделы</span>
                        <ul className='sidebar-services-nav--ul'>
                            <li className='sidebar-services-nav--li'>
                                <Link className='sidebar-services-nav--link' to={'/services/' + newNav.slug[0]}>{newNav.title[0]}</Link>
                            </li>
                            <li className='sidebar-services-nav--li'>
                                <Link className='sidebar-services-nav--link' to={'/services/' + newNav.slug[1]}>{newNav.title[1]}</Link>
                            </li>
                            <li className='sidebar-services-nav--li'>
                                <Link className='sidebar-services-nav--link' to={'/services/' + newNav.slug[2]}>{newNav.title[2]}</Link>
                            </li>
                            <li className='sidebar-services-nav--li'>
                                <Link className='sidebar-services-nav--link' to={'/services/' + newNav.slug[3]}>{newNav.title[3]}</Link>
                            </li>
                        </ul>
                    </div> */}
                </div>
                   
            </Col>
        )
}

export default Sidebar

    // function excludeCurPage(object) {
    //     const services = 'services/';
    //     let newArray = [];
    //     if (typeof window !== `undefined`) {
    //         const url = window.location.href;
    //         const start = url.indexOf(services);
    //         const end = url.indexOf('/', start + services.length);
    //         const curPage = url.substring(start + services.length, end === -1 ? url.length : end);
    //         object.headerSubNavSlug.map((item) => {
    //             if (item !== curPage) {
    //                 newArray.push(item);
    //             }
    //         })
    //         return newArray;
    //     }
    //     return null;
    // }

    //     function generateNewNav(navigation) {
    //         let slug = excludeCurPage(navigation);
    //         let title = [];
    //         if (slug !== null) {
    //             slug.map((item) => {
    //                 switch(item) {
    //                     case 'stairs':
    //                         title.push('Лестницы');
    //                         break;
    //                     case 'renovation':
    //                         title.push('Реставрация КГИОП');
    //                         break;
    //                     case 'uslugi-po-derevu':
    //                         title.push('Услуги по дереву');
    //                         break;
    //                     case 'interiors':
    //                         title.push('Отделка интерьеров');
    //                         break;
    //                     case 'public-places':
    //                         title.push('Общественные зоны');
    //                         break;
                        
    //                     default:
    //                         break;
    //                 }
    //             })
    //         }
    //         else {
    //             slug = [0,1,2,3,4];
    //             title = [0,1,2,3,4];
    //         }
    //         slug.map((item) => {
    //             switch(item) {
    //                 case 'stairs':
    //                     title.push('Лестницы');
    //                     break;
    //                 case 'renovation':
    //                     title.push('Реставрация КГИОП');
    //                     break;
    //                 case 'uslugi-po-derevu':
    //                     title.push('Услуги по дереву');
    //                     break;
    //                 case 'interiors':
    //                     title.push('Отделка интерьеров');
    //                     break;
    //                 case 'public-places':
    //                     title.push('Общественные зоны');
    //                     break;
                    
    //                 default:
    //                     break;
    //             }
    //         })
            
    //         return {title, slug};
    //     }
        
    //     const newNav = generateNewNav(navigation);

        // function linkToComponent(page) {
        //     const title = 'Калькулятор лестниц'
        //     const slug = 'st-calc'
        //     if (page === 'Лестница на заказ') {
        //         return (
        //             <li className="sidebar-nav--li">
        //             <AnchorLink 
        //             // onClick = {changeTab(slug)}
        //             // offset='-200'
        //             to={slug}
        //             href={`#${slug}`}
        //             spy={true}
        //             // smooth={true}
        //             duration={500}
        //             hashSpy={true}
        //             title={'Перейти к разделу ' + title}
        //             className={activeTab === slug ? 'sidebar-nav--link sidebar-nav--link_b sidebar-nav--link_active' : 'sidebar-nav--link sidebar-nav--link_b'}
        //             activeClass="sidebar-nav--link_active"
        //             >
        //             {title}
        //             </AnchorLink> 
        //             </li>
        //         )
        //     }
        // }