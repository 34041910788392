import React from 'react';
import './Vacancies.scss'
import { Container, Row, Col } from 'react-bootstrap'
import Button from '../Button/Button'

export default ({data, mail}) => {
    const duties = 'Обязанности:';
    const requirements = 'Требования:';
    return (
        <section className="vacancies">
            <Container className='vacancies-wrapper' fluid='xl'>
                {
                    data.map((vacancy) => {
                        return (
                            <section key={vacancy.title} className="vacancy">
                            <h4 className="vacancy--title">{vacancy.title}</h4>
                            <div className="vacancy--desc" dangerouslySetInnerHTML={{__html: vacancy.description.childMarkdownRemark.html}}/>
                            <Row className='mb-5'>
                                <Col xs={12} md={6}>
                                    <p className='vacancy--list-title'>{duties}</p>
                                    <ul className="vacancy--ul">
                                        {
                                            vacancy.duties.map((item) => {
                                                return (
                                                    <li key={item} className="vacancy--li">{item}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </Col>
                                <Col xs={12} md={6}>
                                    <p className='vacancy--list-title'>{requirements}</p>
                                    <ul className="vacancy--ul">
                                        {
                                            vacancy.requirements.map((item) => {
                                                return (
                                                    <li key={item} className="vacancy--li">{item}</li>
                                                )
                                            })
                                        }
                                    </ul>
                                </Col>
                            </Row>
                            <Button extraClasses='vacancy--btn' as='external' text='Ответить на вакансию' linkTo={'mailto:' + mail}/>
                        </section>
                        )
                       
                    })
                }

            </Container>
        </section>
    )
}
