import React from 'react'
import './Employees.scss'
import { Container , Row, Col } from 'react-bootstrap'
import Img from 'gatsby-image'
import normalizePhone from '../../functions/phone-normalizer'

export default ({employees}) => {

    function chooseContact(contact) {
        if (contact.includes('@')) {
            return <a href={'mailto:' + contact} className="employee--link">{contact}</a>
        }
        else return <a href={'tel:' + contact} className="employee--link">{normalizePhone(contact)}</a>
    }
    return (
        <div className="employees">
        <Container  fluid={'xl'}>
            <div className="employees--wrapper">
                {
                    employees.map((employee) => {
                        return (
                            <div className="employees-employee employee" key={employee.name}>
                                <Img className='employee--photo' fluid={employee.photo.fluid} alt={employee.photo.title}/>
                                <div className="employee--desc">
                                    <p className="employee--name">{employee.name}</p>
                                    <p className="employee--position">{employee.position}</p>
                                    <ul className="employee--contacts">
                                        {
                                            employee.contacts.map((contact) => {
                                                return (
                                                    <li className="employee--contact" key={contact}>
                                                        {chooseContact(contact)}
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        )

                    })
                }

            </div>
        </Container>
        </div>
    )
}