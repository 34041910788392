import React from 'react'
import { graphql } from 'gatsby'

import { Container, Row, Col } from 'react-bootstrap'

import '../styles/templates/service-page-template.scss'
import '../styles/pages/about.scss'

import Layout from '../components/Layout/Layout'
import Seo from '../components/Seo/Seo'
import Sidebar from '../components/Sidebar/Sidebar'
import Vacancies from '../components/Vacancies/Vacancies'
import FactoryGallery from '../components/FactoryGallery/FactoryGallery'
import Employees from '../components/Employees/Employees'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'

export default ({data, location, pageContext}) => {
    const {
        breadcrumb: { crumbs },
    } = pageContext
    console.log(crumbs, 'CRUMBS')
    crumbs[crumbs.length - 2].crumbLabel = 'О компании'

    function chooseComponent(component) {
        switch(component) {
            case 'Вакансии':
                return <Vacancies data = {data.allContentfulVacancy.nodes} mail={data.contentfulCompanyContacts.vacanciesEmail}/>
            case 'Фотогалерея':
                return <FactoryGallery photos = {data.contentfulFactoryGallery.photos}/>
            case 'Сотрудники':
                return <Employees employees = {data.allContentfulEmployee.nodes}/>
        }
    }

    const extraItem = {
        title: 'О компании',
        slug: 'about'
      }
    return (
        <Layout>
            <Seo 
                title = {data.contentfulAboutSection.title}
            />
            <Container className='content'  fluid={'xl'}>
            <Row className="section-with-sidebar">
                <Sidebar 
                    sectionNavigation = {data.allContentfulAboutSection.nodes}
                    extraItem = {extraItem}
                    currentSection = 'about'
                />
                <Col className="page-with-sidebar" xs={12} md={8} lg={9}>
                    <Breadcrumb
                        crumbs={crumbs}
                        crumbLabel={data.contentfulAboutSection.title}
                    />
                    <h1 className="service-page--title">{data.contentfulAboutSection.title}</h1>
                    <div className='about-section--body' 
                          dangerouslySetInnerHTML={{__html: data.contentfulAboutSection.body.childMarkdownRemark.html}}
                    />
                </Col>
            </Row>
            </Container>
            {chooseComponent(data.contentfulAboutSection.component)}
        </Layout>
    )
}

export const aboutPageBySlug = graphql`
    query aboutPageBySlug($slug: String!) {
        contentfulAboutSection(slug: {eq: $slug}) {
        title
            body {
                childMarkdownRemark {
                    html
                }
            }
            component
        }
        contentfulAboutSection(slug: {eq: $slug}) {
    title
        body {
            childMarkdownRemark {
                html
            }
        }
        component
    }
    allContentfulAboutSection(filter: {title: {ne: "О производстве"}}, sort: {fields: order, order: ASC}) {
        nodes {
            title
            slug
        }
    }
    contentfulCompanyContacts(title: {eq: "Дерево Желаний"}) {
        vacanciesEmail
    }
    allContentfulVacancy {
        nodes {
            title
            description {
                childMarkdownRemark {
                    html
                }
            }
            requirements
            duties
        }
    }
    allContentfulEmployee(sort: {fields: order, order: ASC}) {
    nodes {
        name
        position
        contacts
        photo {
            fluid(maxWidth: 800) {
                ...GatsbyContentfulFluid
            }
        title
        }
    }
    }
    contentfulFactoryGallery {
        photos {
        fluid(maxWidth: 400) {
            base64
            # tracedSVG
            srcWebp
            srcSetWebp
        }
        title
        file {
            url
            details {
                image {
                height
                width
                }
            }
        }
        }
    }
    }
`
